import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';
import {CaptureConsole} from '@sentry/integrations';
import lodash from 'lodash';
import axios from 'axios';
import alpinejs from 'alpinejs';
import PortalVue from 'portal-vue';

Sentry.init({
	Vue,
	dsn: process.env.SENTRY_JS_DSN,
	environment: process.env.APP_ENV,
	integrations: [
		new Integrations.BrowserTracing(),
		new CaptureConsole({
			levels: ['error']
		})
	],
	tracesSampleRate: 1.0,
	beforeSend: (event, hint) => {
		if(process.env.APP_DEBUG) {
			console.warn(hint.originalException || hint.syntheticException || event)
		}

		//Log only on staging and production environments.
		if (process.env.APP_ENV !== 'local'){ return event; }
	},
});

if(window.auth_user) {
	Sentry.setUser({id: window.auth_user.id, name: window.auth_user.name, email: window.auth_user.email});
}

window.Vue = Vue;

window._ = lodash;

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

window.Alpine = alpinejs

Vue.use(PortalVue);
